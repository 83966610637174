import { GetHomePromotionDto } from "@/api";
import { API_ADDRESS } from "@/api/client";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

export const Category = ({
  promotionLogoUrl,
  promotionId,
  promotionName,
}: GetHomePromotionDto) => {
  const router = useRouter();

  return (
    <div
      className="flex flex-col items-center"
      onClick={() => router.push(`/app/promotion/${promotionId}`)}
    >
      <Image
        width={47}
        height={47}
        id={promotionLogoUrl}
        src={
          promotionLogoUrl?.includes("/assets")
            ? promotionLogoUrl
            : API_ADDRESS + "/common/images/public/" + promotionLogoUrl
        }
        alt="category"
      />
      <span className="text-[#000000] text-[12px]">{promotionName}</span>
    </div>
  );
};
