import { BottomNavigator } from "@/components/BottomNavigator/BottomNavigator";
import { MainHeader } from "@/components/MainHeader/MainHeader";
import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";

type Props = {
  children: React.ReactNode;
  isFullScreen?: boolean;
  isHeaderVisible?: boolean;
  isBottomVisible?: boolean;
  goToTopButtonVisible?: boolean;
  className?: string;
};

export const MobileMainScreenWrapper = ({
  children,
  isFullScreen = false,
  isHeaderVisible = true,
  isBottomVisible = true,
  goToTopButtonVisible = true,
  className
}: Props) => {
  const [isGoingToTop, setIsGoingToTop] = useState(false);
  const scrollArea = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsGoingToTop(
        window.innerHeight / 2 < (scrollArea.current?.scrollTop as number)
      );
    };

    // 스크롤 이벤트 리스너 추가
    scrollArea.current?.addEventListener("scroll", handleScroll);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      scrollArea.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex flex-col h-full overflow-x-hidden overflow-y-auto relative">
      {!isFullScreen && isHeaderVisible && <MainHeader />}
      <div
        className={classNames("flex-1 overflow-y-auto h-[calc(100%-67px)] bg-[#FAF7F4]", className)}
        ref={scrollArea}
      >
        {children}
      </div>
      {!isFullScreen && isBottomVisible && <BottomNavigator />}
      {goToTopButtonVisible && (
        <div
          className={classNames(
            "absolute flex items-center justify-center bottom-[80px] right-[14px] w-[32px] h-[32px] rounded-full bg-white border-[0.5px] border-[#262626] transition-opacity cursor-pointer",
            isGoingToTop ? "opacity-[1]" : "opacity-0"
          )}
          onClick={() =>
            scrollArea.current?.scrollTo({ top: 0, behavior: "smooth" })
          }
        >
          <Image
            src="/assets/chevron-up.svg"
            alt="Arrow Up"
            width={24}
            height={24}
          />
        </div>
      )}
    </div>
  );
};
