import { MobileMainScreenWrapper } from "@/template/MobileMainScreenWrapper";

import { HomeScreen } from "@/screen/Home/HomeScreen";

export default function Home() {
  return (
    <MobileMainScreenWrapper>
      <HomeScreen />
    </MobileMainScreenWrapper>
  );
}
