import {
  GetFashionStyleAssessmentAnswerDto,
  GetFashionStyleAssessmentGenderTypeEnum,
  GetFashionStyleRes,
} from "@/api";
import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

export type FashionStyleAssessment = {
  product?: GetFashionStyleRes;
  fashionStyleAssessmentId?: number;
  question?: string;
  answers?: Array<GetFashionStyleAssessmentAnswerDto>;
};

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppFashionStyle.getFashionStyleAssessment(
    args[1] === "male" ? "MALE" : "FEMALE" 
  );

  return data;
};

export const useGetFashionStyleAssessment = (sex: string) => {
  return useSWR(["/app/fashionStyle/assessment", sex], fetcher);
};
