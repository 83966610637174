import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppHome.getNotificationList(
    args[1]
  );

  return data;
};

export const useGetNotification = () => {
  return useSWR(["/app/home/notifications", "me"], fetcher);
};
