import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { GetHomeBannerDto } from "@/api";
import { API_ADDRESS } from "@/api/client";
import { useRouter } from "next/router";

type Props = {
  banner: Array<GetHomeBannerDto>;
};

export const Banner = ({ banner }: Props) => {
  const router = useRouter();

  return (
    <div className="aspect-square bg-gray-200">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        pagination={{
          type: "fraction",
          renderFraction: function (currentClass: string, totalClass: string) {
            if (banner.length === 1) return "<></>";

            return (
              '<div class="py-[2px] px-[4px] ml-auto flex w-fit mr-[12px] mb-[12px]"  style="background:' +
              "rgba(0, 0, 0, 0.30)" +
              ' ">' +
              '<span class="text-10-medium text-white ' +
              currentClass +
              '"></span>' +
              '<span class="text-10-medium text-white"> / </span>' +
              '<span class="text-10-medium text-white ' +
              totalClass +
              '"></span>' +
              "</div>"
            );
          },
        }}
        navigation={true}
        modules={[Pagination, Navigation, Autoplay]}
        className="h-full"
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          stopOnLastSlide: false,
          waitForTransition: true,
        }}
      >
        {banner.map((bannerItem, idx) => (
          <SwiperSlide
            key={idx}
            onClick={() => router.push(bannerItem.hrefUrl as string)}
          >
            <img src={API_ADDRESS + bannerItem.imageUrl} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
