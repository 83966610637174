import useSWR from "swr";

export const useAuthToken = () => {
  const { data, mutate, error, isLoading } = useSWR<any>("token", null, {
    fallbackData: false,
    revalidateOnMount: false,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    token: data as string,
    isLoading,
    mutate,
    error,
  };
};
