import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppUser.getCouponList(
    args[1]
  );

  return data;
};

export const useGetCoupon = () => {
  return useSWR(["/app/user/coupons", "me"], fetcher);
};
