import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

const fetcher = async (args: FetcherArgsType) => {
  const result = await APIClient.AppFashionStyle.fashionStyleProductList(
    args[1]
  );

  return result.data;
};

export const useGetFashionStyleProduct = (token: string) => {
  return useSWR(["/app/fashionStyle/products", token], fetcher);
};
