import { ProductDto } from "@/api";
import { API_ADDRESS } from "@/api/client";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

type Props = {} & ProductDto;

export const Product = ({
  brandName,
  productName,
  productSeason,
  originalAmount,
  finalAmount,
  discountPercent,
  additionalDiscountPercent,
  productImageUrl,
  productId,
}: Props) => {
  const router = useRouter();

  return (
    <div
      className="w-[50%] flex flex-col cursor-pointer"
      onClick={() => router.push(`/app/product/${productId}`)}
    >
      <div className="w-full aspect-square mb-[8px] border-y-[0.5px] border-r-[0.5px] border-[#737373] relative">
        {productImageUrl && (
          <Image src={API_ADDRESS + productImageUrl} fill alt="Product Image" />
        )}
      </div>
      <div className="h-[137px] ml-[12px] flex flex-col">
        <label className="text-12-semi-bold">{brandName}</label>
        <p className="text-12-medium text-[#737373] mt-[2px] min-h-[34px] overflow-hidden overflow-ellipsis line-clamp-2">
          {productName}
        </p>

        <div className="flex flex-col">
          <span className="text-10-medium text-[#737373] mt-[8px]">
            {productSeason}
          </span>
          <div className="flex items-center">
            <span className="text-12-medium text-[#E00000]">
              {discountPercent}%
            </span>
            {additionalDiscountPercent && (
              <span className="text-12-medium text-[#0080B8] ml-[2px]">
                + {additionalDiscountPercent}%
              </span>
            )}

            <span className="text-12-medium text-[#737373] ml-[4px]">
              {originalAmount?.toLocaleString()}원
            </span>
          </div>
          <span className="text-14-semi-bold">
            {finalAmount?.toLocaleString()}원
          </span>
        </div>
      </div>
    </div>
  );
};
