import React from "react";

type Props = {
  children: React.ReactNode;
  title: string;
};

export const ProductSection = ({ children, title }: Props) => {
  return (
    <div className="flex flex-col">
      <label
        className="text-16-bold mx-[20px] my-[16px]"
        dangerouslySetInnerHTML={{ __html: title.replaceAll("\\n", "<br />") }}
      />
      <div className="flex flex-wrap">{children}</div>
    </div>
  );
};
