import { Banner } from "@/components/Banner/Banner";
import { Category } from "@/components/Category/Category";
import { GotoExamSection } from "@/components/GotoExamSection/GotoExamSection";
import { GotoLimitedSection } from "@/components/GotoLimitedSection/GotoLimitedSection";
import { Product } from "@/components/Product/Product";
import { ProductSection } from "@/components/ProductSection/ProductSection";
import { useGetAppHome, useGetMe } from "@/fetcher";
import { useGetCart } from "@/fetcher/useGetCart";
import { useAuthToken } from "@/hooks/useAuthToken";
import { useLocalStorage } from "@mantine/hooks";
import { deleteCookie, getCookie } from "cookies-next";
import { useRouter } from "next/router";
import React, { useEffect } from "react";

import "swiper/css";
import "swiper/css/pagination";

export const HomeScreen = () => {
  const { push } = useRouter();
  const { token } = useAuthToken();
  const { data } = useGetAppHome(token);
  const { mutate } = useGetCart();
  const { data: profile } = useGetMe();
  const [value, setValue] = useLocalStorage({
    key: "IS_FIRST_FASHION_CHECK",
    defaultValue: "",
  });

  useEffect(() => {
    const token = getCookie("token");

    mutate();

    if (token != null) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          token: token,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (profile?.hasFashionStyleId) {
      setValue("true");
    }
  }, [profile]);

  useEffect(() => {
    const token = getCookie("token");

    if (token != null) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          token: token,
        }),
      );
    }
  }, []);

  return (
    <div className="w-full h-full">
      <Banner banner={data?.bannerList || []} />
      <div className="p-[24px] grid-cols-4 grid gap-y-[24px] border-t-[0.5px] border-[#262626] border-b-[0.5px]">
        <Category
          promotionLogoUrl="/assets/all.svg"
          promotionId={999}
          promotionName="전체"
        />

        {data?.promotionList?.map((promotion) => (
          <Category key={promotion.promotionId} {...promotion} />
        ))}
      </div>
      <GotoExamSection
        onClick={() => {
          if (profile?.hasFashionStyleId) {
            push({
              pathname: "/fashion-check/result",
              query: {
                id: profile.userFashionStyleId,
                isFirstExam: !profile.hasFashionStyleId,
              },
            });
          } else {
            push("/fashion-check");
          }
        }}
      />
      {profile?.hasFashionStyleId && (
        <ProductSection title="디스코가 추천하는 취향 발견템">
          {data?.recommendProductList?.map((product) => (
            <Product key={product.productId} {...product} />
          ))}
        </ProductSection>
      )}

      {profile?.hasFashionStyleId && (
        <GotoLimitedSection onClick={() => push("/app/limited")} />
      )}
      <ProductSection title="DISCO PICK">
        {data?.discoPickProductList?.map((product) => (
          <Product key={product.productId} {...product} />
        ))}
      </ProductSection>
      <div className="h-[100px]" />
    </div>
  );
};
