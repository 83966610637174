import { GetUserCartOfferDto, GetUserCartOfferProductDeliveryDto } from "@/api";
import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

export type CartItem = GetUserCartOfferDto & {
  deliveryFee: number;
  couponId?: number;
  couponAmount?: number;
};

export type Cart = {
  brandId: number;
  list: Array<CartItem>;
  totalAmount: number;
  deliveryFee: number;
};

type CartHook = {
  totalList: number;
  list: Cart[];
};

function groupByBrandIdEfficient(
  array?: Array<GetUserCartOfferDto>,
  delivery?: Array<GetUserCartOfferProductDeliveryDto>
) {
  // 임시 객체를 사용해 그룹화
  const grouped = array?.reduce((acc: any, item) => {
    if (!acc[item.brandId as number]) {
      acc[item.brandId as number] = [];
    }
    acc[item.brandId as number].push({
      ...item,
      deliveryFee: delivery?.find((i) => i.productId === item.productId)
        ?.deliveryAmount,
      couponId: undefined,
      couponAmount: undefined,
    });
    return acc;
  }, {});

  // 원하는 형식으로 변환
  return Object.entries(grouped).map(([brandId, list]) => ({
    brandId: parseInt(brandId),
    totalAmount: (list as Cart["list"]).reduce(
      (acc, cur) => (acc += cur.totalAmount as number),
      0
    ),
    deliveryFee: (list as Cart["list"]).reduce(
      (acc, cur) => (acc += cur.deliveryFee as number),
      0
    ),
    list,
  }));
}

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppUserOffer.getOfferList(args[1]);

  return {
    totalList: data.offerList?.length,
    list: groupByBrandIdEfficient(
      data.offerList,
      data.productDeliveryList
    ) as Cart[],
  } as CartHook;
};

export const useGetCart = () => {
  return useSWR(["/app/user/cart", "me"], fetcher);
};
