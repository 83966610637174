import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, number];

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppProduct.getProductDetail("",
    args[1]
  );

  return data;
};

export const useGetProductDetail = (id: number) => {
  return useSWR(["/app/product/", id], fetcher);
};
