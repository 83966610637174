import classNames from "classnames";
import React from "react";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

export const GotoExamSection = ({ className, ...props }: Props) => {
  return (
    <div
      className={classNames(
        "relative w-full  flex flex-col items-center mt-[20px] border-[#262626] border-b-[0.5px] cursor-pointer",
        className
      )}
      {...props}
    >
      <span className="text-16-bold">나만의 취향을 발견해보세요</span>
      <div
        className="mt-[20px] h-[178px] w-full mb-[50px]"
        style={{
          backgroundImage: "url('/illustrator/hero-section.svg')",
          backgroundSize: "100% 100%",
        }}
      />
    </div>
  );
};
