import { APIClient } from "@/api/client";
import { deleteCookie } from "cookies-next";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppUser.getUserInfo(args[1]);

  const uuidRegex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

  if (uuidRegex.test(data?.nickName as string)) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    deleteCookie("token");

    window.location.pathname = "/onboard";
  }

  return data;
};

export const useGetMe = () => {
  return useSWR(["/app/user", "me"], fetcher);
};
