import { useGetNotification } from "@/fetcher";
import { useNavigation } from "@/hooks/useNavigation";
import Image from "next/image";
import { useRouter } from "next/router";
import React from "react";

export const MainHeader = () => {
  const { navigationTo } = useNavigation();
  const { data } = useGetNotification();

  return (
    <div className="w-full h-[50px] px-[20px] flex items-center bg-[#FAF7F4]">
      <Image width={62.7} height={13.65} src="/assets/symbol.svg" alt="LOGO" />
      <div className="ml-auto flex gap-[16px]">
        <Image
          width={24}
          height={24}
          src="/assets/search.svg"
          onClick={() => navigationTo("SEARCH")}
          alt="SEARCH"
          className="cursor-pointer"
        />
        <div className="relative">
          <Image
            width={24}
            height={24}
            onClick={() => navigationTo("NOTIFICATION")}
            src="/assets/bell.svg"
            alt="BELL"
            className="cursor-pointer"
          />
          {data?.notificationList?.filter((i) => !i.notificationRead).length !==
            0 && (
            <div className="w-[8px] h-[8px] rounded-full bg-[#E00000] absolute top-0 right-[3px]" />
          )}
        </div>
      </div>
    </div>
  );
};
