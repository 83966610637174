import React from "react";

type Props = {} & React.HTMLAttributes<HTMLDivElement>;

export const GotoLimitedSection = ({ ...props }: Props) => {
  return (
    <div
      className="relative w-full min-h-[150px] flex flex-col items-center cursor-pointer my-5"
      {...props}
    >
      <div className="border-[0.5px] border-[#F00] w-full h-full flex flex-col items-center rounded-[60%] py-[50px]">
        <span className="text-16-bold">깜짝! 한정상품 OPEN</span>
        <p className="text-12-medium text-center mt-[4px]">
          이거 완전 내 옷이잖아?
          <br />
          취향에 딱! 맞을 것 같은 옷은 <strong>할인+추가할인!</strong>
        </p>
        <span className="text-10-medium text-[#525252] mt-[8px]">
          지금 바로 나만을 위한 한정상품을 확인해보세요.
        </span>
      </div>
    </div>
  );
};
