import { STACK } from "@/constant/stack";
import { useRouter } from "next/router";

export type NAVIGATION_SCREENS = keyof typeof STACK;

export function useNavigation() {
  const { push } = useRouter();

  const navigationTo = (screen: NAVIGATION_SCREENS) => {
    push(STACK[screen]);
  };
  return { navigationTo };
}
