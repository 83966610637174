import { useGetCart } from "@/fetcher/useGetCart";
import { NAVIGATION_SCREENS, useNavigation } from "@/hooks/useNavigation";
import classNames from "classnames";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

const NAVIGATION_LIST = [
  {
    id: "home",
    label: "홈",
  },
  {
    id: "limited",
    label: "한정상품",
  },
  {
    id: "cart",
    label: "장바구니",
  },
  {
    id: "mypage",
    label: "프로필",
  },
];

export const BottomNavigator = () => {
  const { pathname } = useRouter();
  const { navigationTo } = useNavigation();
  const [navigation, setNavigation] =
    useState<(typeof NAVIGATION_LIST)[number]["id"]>("home");

  const { data } = useGetCart();

  useEffect(() => {
    if (pathname != null)
      setNavigation(pathname.split("/")[2]?.toLocaleLowerCase() || "home");
  }, [pathname]);

  const onClickBottomNavigation = (id: string) => () => {
    setNavigation(id);
    navigationTo(id.toUpperCase() as NAVIGATION_SCREENS);
  };

  return (
    <div className="flex w-full px-[16px] pb-[20px] border-t-[0.5px] border-[#A3A3A3] fixed bottom-0 bg-[#FAF7F4] max-w-[430px]">
      {NAVIGATION_LIST.map(({ id, label }, ix) => (
        <div
          onClick={onClickBottomNavigation(id)}
          className="flex-1 flex items-center justify-center flex-col cursor-pointer relative"
          key={id + "-" + ix}
        >
          <Image
            src={`/assets/bottom/${id}.svg`}
            width={32}
            height={32}
            alt="bottom-tab-icon"
            className={classNames(
              "[&>path]:fill-[#262626]",
              navigation === id ? "" : "opacity-[0.2]"
            )}
          />
          <span
            className={classNames(
              "text-[9px] text-[#262626]",
              navigation === id ? "" : "opacity-[0.7]"
            )}
          >
            {label}
          </span>
          {id === "cart" && (
              <div className="bg-[#E00000] rounded-full w-[14px] h-[14px] text-[9px] text-white flex items-center justify-center absolute top-[2px] right-[30%]">
              {data?.totalList}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
