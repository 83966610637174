import { APIClient } from "@/api/client";
import useSWR from "swr";

type FetcherArgsType = readonly [string, string];

const fetcher = async (args: FetcherArgsType) => {
  const { data } = await APIClient.AppHome.search(
    "",
    args[1]
  );

  return data;
};

export const useGetSearch = (token: string) => {
  return useSWR(["/app/home/search", token], fetcher);
};
